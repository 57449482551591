import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "userAgreement" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "textBox" }

import router from '@/router';
import { lang } from '@/lang';


export default /*@__PURE__*/_defineComponent({
  __name: 'userAgreement',
  setup(__props) {

const ret = () => {
  router.go(-1);
};
const title_name = lang.v.title_name;

return (_ctx: any,_cache: any) => {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_nav_bar, {
        onClickLeft: ret,
        title: _unref(title_name),
        "left-arrow": "",
        border: false,
        "left-arrow-color": "rgba(68, 68, 68, 1)"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t1), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t2), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t3), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t4), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t5), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t6), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t7), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t8), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t9), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t10), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t11), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t12), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t13), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t14), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t15), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t16), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t17), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t18), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t19), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t20), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t21), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t22), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t23), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t24), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t25), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t26), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t27), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t28), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t29), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t30), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t31), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t32), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t33), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t34), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t35), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t36), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t37), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t38), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t39), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t40), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t41), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t42), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t43), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t44), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t45), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t46), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t47), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t48), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t49), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t50), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t51), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t52), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t53), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t54), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t55), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t56), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t57), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t58), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t59), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t60), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t61), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t62), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t63), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t64), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t65), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t66), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t67), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t68), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t69), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t70), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t71), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t72), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t73), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t74), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t75), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t76), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t77), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t78), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t79), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t80), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t81), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t82), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t83), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t84), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t85), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t86), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t87), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t88), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t89), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t90), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t91), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t92), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t93), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t94), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t95), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t96), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t97), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t98), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t99), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t100), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t101), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t102), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t103), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t104), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t105), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t106), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t107), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t108), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t109), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t110), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t111), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t112), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(lang).v.t113), 1)
    ])
  ]))
}
}

})